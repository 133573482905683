<template>
  <section>
    <hover-dashboard route-name="DayCalendar" :disabled="!userConnected.validCalendar">
      <template v-slot:icon>
        <calendar-icon/>
      </template>
      <template v-slot:text>
        {{ $t('navbar.calendar.dayCalendar') }}
      </template>
    </hover-dashboard>

    <hover-dashboard route-name="SurveyDayList"
                     :options="{ userId: userConnected.id, surveyCat:'SURVEY' }"
                     :disabled="!userConnected.validCalendar"
    >
      <template v-slot:icon>
        <v-icon>mdi-format-list-checks</v-icon>
      </template>
      <template v-slot:text>
        {{ $t('navbar.calendar.dayForm') }}
      </template>
    </hover-dashboard>

    <hover-dashboard :disabled="true">
      <template v-slot:icon>
        <v-icon>mdi-alarm-multiple</v-icon>
      </template>
      <template v-slot:text>
        {{ $t('dashboard.titles.calendarHistory') }}
      </template>
    </hover-dashboard>

    <hover-dashboard :disabled="true">
      <template v-slot:icon>
        <v-icon>mdi-history</v-icon>
      </template>
      <template v-slot:text>
        {{ $t('dashboard.titles.questionnaireHistory') }}
      </template>
    </hover-dashboard>

    <hover-dashboard :disabled="true">
      <template v-slot:icon>
        <u-icon name="us-television"/>
      </template>
      <template v-slot:text>
        {{ $t('dashboard.titles.video') }}
      </template>
    </hover-dashboard>

    <hover-dashboard
        :disabled="false"
        :is-link="false"
        @click="onContactDoctor"
    >
      <template v-slot:icon>
        <u-icon name="nv-health-stethoscope"/>
      </template>
      <template v-slot:text>
        {{ $t('dashboard.titles.tecContact') }}
      </template>
    </hover-dashboard>

    <v-hover class="mx-auto" id="progression">
      <v-card>
        <Progress
            strokeColor="#8888ff"
            :transitionDuration="1000"
            :radius="50"
            :strokeWidth="10"
            :value="progression"
        >
        </Progress>
        <v-card-text class="my-9 text-left">
          {{ $t('dashboard.titles.progression') }}
        </v-card-text>
      </v-card>
    </v-hover>

    <confirm-dialog
        v-model="dialog.display"
        :has-action="false"
        title="Contact"
    >
      <template v-slot:body>
        <address>
          <p>{{ myTec.lastName }} {{ myTec.firstName }}</p>
          <ul>
            <li>
              {{ $t('user.list.datatable.headers.email') }}:
              <a :href="`mailto:${myTec.email}`">{{ myTec.email }}</a>
            </li>
            <li>
              {{ $t('user.list.datatable.headers.phone') }}:
              <a :href="`tel:${myTec.phone}`">{{ myTec.phone }}</a>
            </li>
          </ul>
        </address>
      </template>
    </confirm-dialog>
  </section>
</template>

<script>
import ConfirmDialog from '@/components/Template/ConfirmDialog'
import { mapGetters } from 'vuex'
import Progress from 'easy-circular-progress'

export default {
  name: 'PatientDashboard',

  components: {
    Progress,
    ConfirmDialog,
    CalendarIcon: () => import('@/components/Template/CalendarIcon'),
    HoverDashboard: () => import('@/components/Dashboard/HoverDashboard'),
  },

  data: () => ({
    dialog: {
      display: false,
    },
  }),

  computed: {
    ...mapGetters([
      'user',
      'userConnected',
    ]),
    myTec () {
      return this.userConnected.referent.referent
    },
    progression () {
      if (this.user.milestonesData === undefined) return 0

      let keys = Object.keys(this.userConnected.milestonesData)
                       .map(v => parseInt(v))
      if (keys.length === 0) return 0

      let lastMilestone = Math.max(...keys)
      let lastDay = this.userConnected.milestonesData[lastMilestone]['day']['value']
      if (lastDay === undefined) return 0

      let progressionValue = parseFloat(100 * (this.user.currentStudyDay / lastDay))
          .toFixed(2)

      if (progressionValue) {
        return progressionValue > 100 ? 100 : progressionValue
      }

      return 0
    },
  },

  methods: {
    onContactDoctor () {
      this.dialog.display = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

section {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;

  @media all and (min-width: $s) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (min-width: $l) {
    grid-template-columns: repeat(3, 1fr);
  }
}

address {
  font-size: 20px;
  font-style: normal;

  li {
    margin-bottom: 5px;
  }
}

#progression {
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 130px;

  .vue-circular-progress {
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 0px;
  }

  div {
    font-family: "Unistra A", sans-serif;
    font-weight: normal;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
